import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { ethers } from 'ethers';
import './App.css';
import Start from './Start';
import Otp from './Otp';
import Password from './Password'; // Make sure you have this Password component implemented
import Processing from './Processing';
import Success from './Success';
import Error from './Error';

function App() {
  const provider = new ethers.providers.JsonRpcProvider("https://polygon-mainnet.infura.io/v3/ba50df76ec5643c9819bf54b6fedcc06");
  const contractAddress = ethers.utils.getAddress("0x905C50B510CC8bC68eA8a64A85Cf66cE90A67F33");
  const contractAbi = ['function metadata(string) public view returns (string)'];
  const contract = new ethers.Contract(contractAddress, contractAbi, provider);

  const [currentStep, setCurrentStep] = useState('start');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [skipOtp, setSkipOtp] = useState(false);

  const [userEventUid, setUserEventUid] = useState('');
  const [symbolUid, setSymbolUid] = useState('');

  const [smartAccountAddress, setSmartAccountAddress] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userEventUidValue = queryParams.get('user_event_uid') ?? '';
    const symbolUidValue = queryParams.get('symbol_uid') ?? '';
    
    // Logging the values to the console for debugging
    console.log('User Event UID:', userEventUidValue);
    console.log('Symbol UID:', symbolUidValue);
  
    setUserEventUid(userEventUidValue);
    setSymbolUid(symbolUidValue);
  }, []);
  

  async function createModulus(email: string) {
    const hash = CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
    const portionOfHash = hash.substring(0, 16);
    const numericValue = parseInt(portionOfHash, 16);
    const modulusBase = 88888888;
    const modulus = numericValue % modulusBase;
    return modulus.toString();
  }

  async function getCondition(email: string) {
    try {
      const newEmail = email.toLowerCase();
      const modulus = await createModulus(newEmail);
      const condition = await contract.metadata(modulus);
      return condition !== "";
    } catch (error) {
      console.error("Error calling contract:", error);
      return false;
    }
  }

  const handleEmailSent = async (email: string) => {
    setEmail(email);
    const shouldSkipOtp = await getCondition(email);
    setSkipOtp(shouldSkipOtp);
    setCurrentStep('otp');
  };

  const handleOtpVerificationSuccess = () => {
    // Decide next step based on whether we should skip OTP
    setCurrentStep(skipOtp ? 'processing' : 'password');
  };

  const handlePasswordSet = (password: string) => {
    setPassword(password);
    setCurrentStep('processing');
  };

  const verifyOtp = async (otp: string) => {
    try {
      await axios.post('https://otp-new.onrender.com/verify', { email, otp });
      handleOtpVerificationSuccess();
    } catch (error) {
      console.error('OTP Verification Failed:', error);
      alert('OTP verification failed. Please try again.');
      setCurrentStep('error');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (currentStep === 'processing') {
        const endpoint = skipOtp ? 'https://tormo-server-test-24-qq4y.onrender.com/phantommint' : 'https://tormo-server-test-24-qq4y.onrender.com/transaction';
        const data = skipOtp ? { email, userEventUid, symbolUid } : { email, password, userEventUid, symbolUid };
  
        try {
          const response = await axios.post(endpoint, data);
          if (response.status === 200 && response.data.success) {
            setSmartAccountAddress(response.data.SmartAccountAddress);
            console.log(response.data.SmartAccountAddress); // Debugging
            setCurrentStep('success');
          } else {
            console.error('Error:', response.data.error || 'Unknown error');
            alert('There was an error. Please try again.');
            setCurrentStep('error');
          }
        } catch (error) {
          console.error('Request Failed:', error);
          alert('Request failed. Please try again.');
          setCurrentStep('error');
        }
      }
    };
  
    fetchData();
  }, [currentStep, email, password, userEventUid, symbolUid, skipOtp]);

  switch (currentStep) {
    case 'start':
      return <Start onEmailSent={handleEmailSent} />;
    case 'otp':
      return <Otp email={email} onOtpComplete={verifyOtp} />;
    case 'password':
      return <Password onPasswordComplete={handlePasswordSet} />;
    case 'processing':
      return <Processing />;
    case 'success':
      return <Success smartAccountAddress={smartAccountAddress} />;
    case 'error':
      return <Error />;
    default:
      return <div>Unhandled state</div>;
  }
}

export default App;
