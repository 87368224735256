import React, { useState } from 'react';
import TORMO from './images/S__5570563-1.png';
import './App.css';

interface PasswordProps {
    onPasswordComplete: (password: string) => void;
}
/*
function Password({ onPasswordComplete }: PasswordProps) {
    const [password, setPassword] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault(); // Prevent form from causing a page reload
      onPasswordComplete(password);
    };
*/
function Password({ onPasswordComplete }: PasswordProps) {
    const [password, setPassword] = useState('');

    const isValidPassword = (password: string) => {
      const cleanedPassword = password.replace(/\s+/g, ''); // Remove all spaces
      const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/; // Regex to check for at least one letter, one number, and at least 6 characters
      return regex.test(cleanedPassword);
  };
    
  const handleSubmit = () => {
    const cleanedPassword = password.replace(/\s+/g, ''); // Remove all spaces
    if (isValidPassword(cleanedPassword)) {
        // Call the passed in prop function with the cleaned and validated password
        onPasswordComplete(cleanedPassword);
    } else {
        alert('Password must be at least 6 characters long and include both letters and numbers.');
    }
};
  return (
<div className="body">
  <div className="div-block">
    <img src={TORMO} loading="lazy" alt="" className="image" />
  </div>
  <div className="div-password">
    <div className="div-password-inside">
      <h1 className="heading votething">PASSWORD</h1>
      <div className="text-block votething">パスワード(6桁以上)を入力してください。</div>
      <div className="form-block votething w-form">
        <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form" data-wf-page-id="65dbfd579fbb21da5b6712f1" data-wf-element-id="2b4f41e8-600d-bd00-e27a-68aa3f1db769">
        <input 
          className="text-field email w-input" 
          maxLength={50} 
          name="password" 
          placeholder="Password" 
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)} 
        />
        </form>
      </div>
      <div className="form-block _4digits w-form">
        <form  id="email-form" name="email-form" data-name="Email Form" method="get" className="form" data-wf-page-id="65dbfd579fbb21da5b6712f1" data-wf-element-id="2b4f41e8-600d-bd00-e27a-68aa3f1db77b">
          <button type="button" className="button v w-button" onClick={handleSubmit}>SUBMIT</button>
        </form>
      </div>
    </div>
  </div>
</div>
  );
}

export default Password;
