import React from 'react';
import TORMO from './images/S__5570563-1.png';
//import NFT from './images/bdb0e7b18993f3be5b51d3ca187c75f9.png';
import './App.css';

interface SuccessProps {
  smartAccountAddress: string;
}

function Success({ smartAccountAddress }: SuccessProps) {

const walletViewerUrl = `https://viewer.tormo.online/viewer/${smartAccountAddress}`;

const nftImageUrl = "https://ipfs.io/ipfs/QmRGyuNX8AWvpMPGC2yePuHCsgkku7YRRZjgMLA2GyS6Kb";

  return (
<div className="body">
  <div className="div-block">
    <img src={TORMO} loading="lazy" alt="" className="image" />
  </div>
  <div className="div-password">
    <div className="div-password-inside">
      <h1 className="heading votething">You got a new NFT</h1>
      <div className="success-image">
        <video src={nftImageUrl} controls className="nft-image" />
      </div>
      <div className="form-block _4digits passkey w-form">
        <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form passkey" data-wf-page-id="65dd5251c5c0b345890b1ee0" data-wf-element-id="ea355fd7-d9e5-fa18-d06c-995cfe50d65a">
        <a href={`https://viewer.tormo.online/viewer/${smartAccountAddress}`} className="button v w-button">Show Wallet</a>
        </form>
      </div>
    </div>
  </div>
</div>
  );
}

export default Success;
