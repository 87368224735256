import React from 'react';
import TORMO from './images/S__5570563-1.png';
import LOAD from './images/Rolling-1s-200px.gif';
import './App.css';

function Processing() {
  return (
<div className="body">
  <div className="div-block">
    <img src={TORMO} loading="lazy" alt="" className="image" />
  </div>
  <div className="div-password">
    <div className="div-password-inside">
      <h1 className="heading votething">PROCESSING</h1>
      <div className="text-block votething">NFTを送っています。</div>
      <div className="loading">
        <img src={LOAD} loading="lazy" alt="" className="loading-gif" />
      </div>
    </div>
  </div>
</div>
  );
}

export default Processing;
