import React, {useState} from 'react';
import TORMO from './images/S__5570563-1.png';
import './App.css';

function Start({ onEmailSent }: { onEmailSent: (email: string) => void }) {
  const [email, setEmail] = useState<string>('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedEmail = event.target.value.trim().toLowerCase();
    setEmail(cleanedEmail);
  };
  const sendOTP = async (e: React.MouseEvent) => {
    e.preventDefault();
    const response = await fetch('https://otp-new.onrender.com/sendCode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    if (response.ok) {
      onEmailSent(email);
    } else {
      alert('Failed to send OTP. Please try again.');
    }
  };
  const isEmailValid = isValidEmail(email);

  return (
<div className="body">
  <div className="div-block">
    <img src={TORMO} loading="lazy" alt="" className="image" />
  </div>
  <div className="div-password">
    <div className="div-password-inside">
      <h1 className="heading votething">Log in / Sign in</h1>
      <div className="text-block votething">メールアドレスを入力してください。</div>
      <div className="form-block votething w-form">
        <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form" data-wf-page-id="65d6f730b0e068d578aabcc9" data-wf-element-id="2b4f41e8-600d-bd00-e27a-68aa3f1db769">
            <input
                className="text-field email w-input"
                maxLength={256}
                name="name"
                data-name="Name"
                placeholder="user@example.com"
                type="text"
                id="name"
                value={email}
                onChange={handleInputChange}
              />
        </form>
      </div>
      <div className="form-block _4digits w-form">
        <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form" data-wf-page-id="65d6f730b0e068d578aabcc9" data-wf-element-id="2b4f41e8-600d-bd00-e27a-68aa3f1db77b">
          <a 
          href="#" 
          className={`button v w-button ${!isEmailValid ? 'disabled' : ''}`}
          onClick={isEmailValid ? sendOTP : undefined}
          >
            SUBMIT
          </a>
        </form>
      </div>
    </div>
  </div>
</div>
  );
}

function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
}

export default Start;