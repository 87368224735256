import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import TORMO from './images/S__5570563-1.png';

interface OtpProps {
  email: string;
  onOtpComplete: (otp: string) => void;
}

function Otp({ email, onOtpComplete }: OtpProps) {
  const [otp, setOtp] = useState<{ otp1: string; otp2: string; otp3: string; otp4: string }>({ otp1: '', otp2: '', otp3: '', otp4: '' });
  const otp1Ref = useRef<HTMLInputElement>(null);
  const otp2Ref = useRef<HTMLInputElement>(null);
  const otp3Ref = useRef<HTMLInputElement>(null);
  const otp4Ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const { otp1, otp2, otp3, otp4 } = otp;
    if (otp1 && otp2 && otp3 && otp4) {
      onOtpComplete(`${otp1}${otp2}${otp3}${otp4}`);
    }
  }, [otp, onOtpComplete]);

  const resendCode = async () => {
    try {
      await axios.post('https://otp-new.onrender.com/resendCode', { email });
      alert('OTP has been resent to your email');
    } catch (error) {
      console.error('Error resending code:', error);
      alert('Error resending OTP. Please try again.');
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof typeof otp,
    nextRef?: React.RefObject<HTMLInputElement>,
    prevRef?: React.RefObject<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (value === '' || (value.length === 1 && value.match(/[0-9]/))) {
      setOtp({ ...otp, [field]: value });
      if (value && nextRef && nextRef.current) {
        nextRef.current.focus();
      } else if (value === '' && prevRef && prevRef.current) {
        prevRef.current.focus();
      }
    }
  };

  return (
    <div className="body">
      <div className="div-block">
        <img src={TORMO} loading="lazy" alt="" className="image" />
      </div>
      <div className="div-password">
        <div className="div-password-inside">
          <h1 className="heading votething">CODE</h1>
          <div className="text-block votething">
            {email} にコードを送信しました。
            <a href="#" onClick={(e) => { e.preventDefault(); resendCode(); }}>
              <span className="text-span">再送</span>
            </a>
          </div>
          <div className="form-block votething otp w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form otp">
              <input
                ref={otp1Ref}
                className="text-field email otp w-input"
                maxLength={1}
                value={otp.otp1}
                onChange={(e) => handleChange(e, 'otp1', otp2Ref)}
                placeholder=""
                type="text"
                inputMode="numeric"
                id="otp1"
                autoFocus
                pattern="[0-9]*" 
              />
              <input
                ref={otp2Ref}
                className="text-field email otp w-input"
                maxLength={1}
                value={otp.otp2}
                onChange={(e) => handleChange(e, 'otp2', otp3Ref, otp1Ref)}
                placeholder=""
                type="text"
                inputMode="numeric"
                id="otp2"
                pattern="[0-9]*"
              />
              <input
                ref={otp3Ref}
                className="text-field email otp w-input"
                maxLength={1}
                value={otp.otp3}
                onChange={(e) => handleChange(e, 'otp3', otp4Ref, otp2Ref)}
                placeholder=""
                type="text"
                inputMode="numeric"
                id="otp3"
                pattern="[0-9]*"
              />
              <input
                ref={otp4Ref}
                className="text-field email otp w-input"
                maxLength={1}
                value={otp.otp4}
                onChange={(e) => handleChange(e, 'otp4', undefined, otp3Ref)}
                placeholder=""
                type="text"
                inputMode="numeric"
                id="otp4"
                pattern="[0-9]*"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
