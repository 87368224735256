import React from 'react';
import TORMO from './images/S__5570563-1.png';
import ErrorImage from './images/error.png';
import './App.css';

function Error() {
  return (
<div className="body">
  <div className="div-block">
    <img src={TORMO} loading="lazy" alt="" className="image" />
  </div>
  <div className="div-password">
    <div className="div-password-inside">
      <h1 className="heading votething">Error occured</h1>
      <div className="success-image">
        <img src={ErrorImage} loading="lazy" alt="" className="nft-image" />
      </div>
      <div className="form-block _4digits passkey w-form">
        <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form passkey" data-wf-page-id="65dd5251c5c0b345890b1ee0" data-wf-element-id="ea355fd7-d9e5-fa18-d06c-995cfe50d65a">
          <a href="https://tormoapp.com" className="button v w-button">Go to LP</a>
        </form>
      </div>
    </div>
  </div>
</div>
  );
}

export default Error;
